import { Controller } from "@hotwired/stimulus"
import { getColorInformation } from "../src/precision_library"

// Connects to data-controller="material-field"
export default class extends Controller {
  static targets = [
    'previewVignette', 'previewSlab', 'previewCloseup', 'previewBlank',
    'selectedVignette', 'selectedSlab', 'selectedCloseup', 'selectedBlank',
    'selectInput',
  ]
  static values = { 
    initialColor: String,
    selectedColor: String,
    initialBrandName: String,
    finalBrandName: String,
  }

  connect() {
    this.allOptions = [...this.selectInputTarget.options];
  }

  changeSelection(event) {
    this.selectedColorValue = event.target.selectedOptions[0].label;
  }

  initialBrandNameValueChanged() {
    this.initialColorValueChanged();
  }
  
  finalBrandNameValueChanged() {
    this.selectedColorValueChanged();
  }

  async initialColorValueChanged() {
    if (this.initialColorValue == null || this.initialColorValue == '' || this.initialBrandNameValue == null || this.initialBrandNameValue == '') {
      this.previewCloseupTarget.parentElement.classList.add('hidden');
      this.previewSlabTarget.parentElement.classList.add('hidden');
      this.previewVignetteTarget.parentElement.classList.add('hidden');
      this.previewBlankTarget.classList.remove('hidden');
      
      return;
    }

    const brandName = this.initialBrandNameValue
    const colorName = this.initialColorValue
    const colorInfo = await getColorInformation(brandName, colorName);
    this.previewBlankTarget.classList.toggle('hidden', !!colorInfo);

    if (colorInfo?.images?.closeupURL) {
      this.previewCloseupTarget.src = colorInfo.images.closeupURL;
      this.previewCloseupTarget.parentElement.classList.remove('hidden');
    } else {
      this.previewCloseupTarget.parentElement.classList.add('hidden');
    }
    if (colorInfo?.images?.slabURL) {
      this.previewSlabTarget.src = colorInfo.images.slabURL;
      this.previewSlabTarget.parentElement.classList.remove('hidden');
    } else {
      this.previewSlabTarget.parentElement.classList.add('hidden');
    }
    if (colorInfo?.images?.vignetteURL) {
      this.previewVignetteTarget.src = colorInfo.images.vignetteURL;
      this.previewVignetteTarget.parentElement.classList.remove('hidden');
    } else {
      this.previewVignetteTarget.parentElement.classList.add('hidden');
    }
  }

  async selectedColorValueChanged() {
    if (this.selectedColorValue == null || this.selectedColorValue == '' || this.finalBrandNameValue == null || this.finalBrandNameValue == '') {
      this.selectedCloseupTarget.parentElement.classList.add('hidden');
      this.selectedSlabTarget.parentElement.classList.add('hidden');
      this.selectedVignetteTarget.parentElement.classList.add('hidden');
      this.selectedBlankTarget.classList.remove('hidden');

      return;
    }

    const brandName = this.finalBrandNameValue
    const colorName = this.selectedColorValue
    const colorInfo = await getColorInformation(brandName, colorName);
    this.selectedBlankTarget.classList.toggle('hidden', !!colorInfo);

    if (colorInfo?.images?.closeupURL) {
      this.selectedCloseupTarget.src = colorInfo.images.closeupURL;
      this.selectedCloseupTarget.parentElement.classList.remove('hidden');
    } else {
      this.selectedCloseupTarget.parentElement.classList.add('hidden');
    }
    if (colorInfo?.images?.slabURL) {
      this.selectedSlabTarget.src = colorInfo.images.slabURL;
      this.selectedSlabTarget.parentElement.classList.remove('hidden');
    } else {
      this.selectedSlabTarget.parentElement.classList.add('hidden');
    }
    if (colorInfo?.images?.vignetteURL) {
      this.selectedVignetteTarget.src = colorInfo.images.vignetteURL;
      this.selectedVignetteTarget.parentElement.classList.remove('hidden');
    } else {
      this.selectedVignetteTarget.parentElement.classList.add('hidden');
    }
  }
}
