const username = "jonathan";
const password = "5eD78@1";
const endpoint = "https://api.library.countertopwizard.com/";

const brandColors = {};
const brandColorInfo = {};

function title(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const ignored_brand_names = /(Q Quartz|Wilsonart)/
const trimmed_brand_suffixes = /(Ultra Compact|Solid Surface|Laminate|SS|Lam|Natural Stone|Quartz|Wood)$/ // from Material Library source
const ignored_brand_colors = /^(Wilsonart).*/

function mappedBrandName(brandName) {
  if (!brandName.match(ignored_brand_names)) {
    brandName = brandName.replace(trimmed_brand_suffixes, '')
  }

  return brandName.trim()
}

function mappedBrandColor(brand, color) {
  if (match = brand.match(ignored_brand_colors)) {
    brand = match[1]
  }
  color = color.replace(brand, '')
  // color = color.replace(/\(.*\)$/, '') // remove "(directional)" etc
  color = color.replace('(directional)', '')

  return color.trim()
}

export async function getColorDisclosure(brand, color) {
  brand = mappedBrandName(brand)
  color = mappedBrandColor(brand, color)
  const url = `disclosures/${encodeURIComponent(brand)}/${encodeURIComponent(color)}`

  return await getJson(url)
}

export async function getBrandColors(brand) {
  const brandKey = brand.toLowerCase();
  if (brandColors[brand.toLowerCase()]) {
    return brandColors[brand.toLowerCase()];
  }

  const response = await fetch(`${endpoint}materials/${encodeURIComponent(brand)}/colors`, {
    headers: {
      'Authorization': `${username}-${password}`,
    }
  });
  if (!response.ok) return []

  brandColors[brandKey] = (await response.json()).map(c => c.color);
  return brandColors[brandKey];
}

export async function getColorInformation(brand, color) {
  brand = mappedBrandName(brand)
  color = mappedBrandColor(brand, color)
  if (brand === '' || color === '') return;

  return await getJson(`materials/${encodeURIComponent(brand)}/colors/${encodeURIComponent(color)}`)
}


export async function getMaterials() {
  return await getJson("materials")
}

export async function getEdges(type) {
  if (!type || type == '') return null

  return await getJson(`edges/${encodeURIComponent(title(type))}?attributes=all`)
}


export async function getInitialEdge(id) {
  return await getJson(`edge/initial/${id}`)
}


export async function getFinalEdge(id) {
  return await getJson(`edge/final/${id}`)
}

let sinkBrands = null
export async function getSinkBrands() {
  if (sinkBrands) return sinkBrands
    
  return await getJson("sinks")
}

const sinkModelsData = {}
export async function getSinkModels(brand) {
  const path = `sinks/${brand}/models?all_attributes=1`
  if (!sinkModelsData[path]) {
    sinkModelsData[path] = await getJson(`sinks/${brand}/models?all_attributes=1`)
  }

  return sinkModelsData[path]
}


export async function getSinkInfo(sink_name) {
  if (!sink_name || sink_name == '') return null

  const [label, brand, model] = sink_name.match(/Sink\s*(\S*)\s*(.*)/)
  const path = `sinks/${brand.replace(" ","%20")}/models/${model.replace(" ","%20").replace("+", "%2B")}`

  return await getJson(path)
}


async function getJson(path) {
  const url = `${endpoint}${path}`

  try {
    const response = await fetch(url, {
      headers: {
        "Authorization": `${username}-${password}`
      }
    })
    if (!response.ok) {
      console.warn(`Fetch unsuccessful: ${url}`, response)
      return null
    }

    return await response.json()
  } catch (error) {
    console.error(`Fetch Failed: ${url}`, error)
    return null
  }
}