import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";

// Connects to data-controller="countergo-session"
export default class extends Controller {
  static targets = ["form"]
  static values = {
    usage: String
  }

  connect() {
    this.element.classList.remove("hidden")
  }

  async triggerSession(e) {
    e.preventDefault()

    const response = await post("/moraware_credentials/usages", {
      body: JSON.stringify({
        usage: this.usageValue
      })
    })
    if (!response.ok) {
      alert("Failed")
      return
    }

    const json = await response.json
    this.formTarget.elements["user"].value = json.credentials.username
    this.formTarget.elements["pwd"].value = json.credentials.password
    this.formTarget.elements["LOGIN"].click()
  }
}
