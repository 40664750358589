import { Controller } from "@hotwired/stimulus"
import { getInitialEdge, getFinalEdge } from "../src/precision_library";

export default class extends Controller {
  static targets = ['image']
  static values = {
    initialId: String,
    finalId: String,
  }

  initialIdValueChanged() {
    this.updateLayout()
  }

  finalIdValueChanged() {
    this.updateLayout()
  }

  async updateLayout() {
    const edgeInfo = await this.getEdgeInfo()
    if (!edgeInfo?.darkKey) {
      this.element.classList.add('hidden')
      return
    }

    this.imageTarget.setAttribute('src', 'https://library.countertopwizard.com/' + edgeInfo.darkKey)
    this.element.classList.remove('hidden')
  }

  async getEdgeInfo() {
    if (this.hasFinalIdValue) {
      return this.getFinalEdgeInfo()
    } else if (this.hasInitialIdValue) {
      return this.getInitialEdgeInfo()
    } else {
      return {}
    }
  }

  async getInitialEdgeInfo() {
    return getInitialEdge(this.initialIdValue)
  }

  async getFinalEdgeInfo() {
    return getFinalEdge(this.finalIdValue)
  }
}
