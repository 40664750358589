import { Application } from "@hotwired/stimulus"
import { isDevelopment } from "../src/util"

const application = Application.start()
import { Dropdown, Modal, Tabs, Toggle  } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('toggle', Toggle)
application.register('tabs', Tabs)


// Configure Stimulus development experience
application.debug = isDevelopment();
window.Stimulus   = application

import Clipboard from '@stimulus-components/clipboard'
application.register('clipboard', Clipboard)

import NestedForm from '@stimulus-components/rails-nested-form'
application.register('nested-form', NestedForm)

import Sortable from '@stimulus-components/sortable'
application.register('sortable', Sortable);

export { application }
