import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-more"
export default class extends Controller {
  static targets = ['template', 'addButton']

  connect() {
    this.addField()
  }

  add(event) {
    event.preventDefault()
    this.addField()
  }

  addField() {
    this.addButtonTargets.forEach(x => x.remove())

    const node = this.templateTarget.content.cloneNode(true)
    this.element.appendChild(node)
  }
}
