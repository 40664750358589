import { Controller } from "@hotwired/stimulus"
import { isTest} from "../src/util";

// Connects to data-controller="paged-navigation"
export default class extends Controller {
  static targets = ['page', 'backButton', 'nextButton', 'saveButton', 'stickyContainer']
  static values = { 
    selectedId: String,
    feedbackData: Object
  } 

  get indexValue() {
    return this.availablePages().map(x => x.id ).indexOf(this.currentPage()?.id)
  }

  connect() {
    setTimeout(() => {
      this.selectedIdValue = this.availablePages()[0].id
      this.updateUI();
    }, 100);
  }

  savePage(event) {
    this._appendCommit(event)
    this._reassignStickyFields()

    this.currentForm?.addEventListener('turbo:submit-end', this._handleSubmitFailure)
    this.currentForm?.setAttribute("novalidate", true)
    this.currentForm?.requestSubmit()
    this.currentForm?.removeAttribute("novalidate")
  }

  _appendCommit(event) {
    const commit = document.createElement('input');
    commit.setAttribute('type', 'hidden')
    commit.setAttribute('name', 'commit')
    commit.setAttribute('value', event.target.innerHTML)

    this.currentForm?.appendChild(commit)
  }

  _reassignStickyFields() {
    if (!this.hasStickyContainerTarget) return
    const form = this.currentForm
    if (!form) return

    this.stickyContainerTarget.querySelectorAll('input, select, textarea').forEach(node => {
      node.setAttribute('form', form.id)
    })
  }

  previousPage(event) {
    event.preventDefault();
    if (this.isFirstPage()) return;

    window.scrollTo(0, 0)
    this.selectedIdValue = this.availablePages()[this.indexValue - 1]?.id
  }

  nextPage(event) {
    event.preventDefault();
    if (this.indexValue >= this.availablePageCount() - 1) return;

    window.scrollTo(0, 0)
    this.selectedIdValue = this.availablePages()[this.indexValue + 1]?.id
  }

  goto(event) {
    event.preventDefault();
    if (!event.params.page || event.params.page == '') return;

    window.scrollTo(0, 0)
    this.selectedIdValue = event.params.page
  }

  refreshUI(event) {
    // TODO: interact with Alpine better
    setTimeout(() => {
      this.updateUI();
    }, 50);
  }

  selectedIdValueChanged() {
    this.updateUI();
    
    const feedbackData = document.querySelector('form[action="/feedbacks"] input[name="feedback[data]"]')
    if (feedbackData) {
      feedbackData.value = JSON.stringify({
        pageTitle: this.currentPage()?.querySelector('h2').textContent,
        pageId: this.currentPage()?.id,

        ...this.feedbackDataValue
      });
    }
  }

  updateDisabled() {
    const requiredFields = this.currentPage()?.querySelectorAll("form > div > div:not([style*='display: none;']) [required]") || []
    const missingFields = [...requiredFields].find(f => {
      return f.value == null || f.value == '';
    });
    const disableSave = !!missingFields;
    
    this.nextButtonTarget.disabled = disableSave;
    this.saveButtonTarget.disabled = disableSave;
  }
  
  // private

  updateUI() {
    this.pageTargets.forEach(p => { 
      p.classList.remove('currentPage')
    });
    this.currentPage()?.classList.add('currentPage');

    if (this.isFirstPage()) {
      this.backButtonTarget.classList.add('hidden');
    } else {
      this.backButtonTarget.classList.remove('hidden');
    }

    if (this.isLastPage()) {
      this.nextButtonTarget.classList.add('hidden')
      this.saveButtonTargets.forEach(x => x.classList.remove('hidden'))
    } else {
      this.nextButtonTarget.classList.remove('hidden')
      this.saveButtonTargets.forEach(x => x.classList.add('hidden') );
    }

    this.updateDisabled();
  }

  availablePages() {
    return this.pageTargets.filter(f => f.style['display'] != 'none' && !f.classList.contains('hidden'))
  }

  availablePageCount() {
    return this.availablePages().length
  }

  currentPage() {
    return document.getElementById(this.selectedIdValue)
  }

  isFirstPage() {
    return this.indexValue == 0;
  }

  isLastPage() {
    return this.availablePageCount() - 1 == this.indexValue
  }

  get currentForm() {
    return this.currentPage()?.querySelector('form')
  }

  _handleSubmitFailure (event) {
    if (event.detail.success || isTest()) return

    alert("Alert: Failed to save previous page. Please go to the previous step and try again.\n\nIf this persists, move to a location with better reception or takes notes.")
  }
}
