import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="analytics"
export default class extends Controller {

  async trackEvent(event) {
    const response = await post('/analytics_events', { body: JSON.stringify(event.params) })
    if (response.ok) {
      location.reload()
    } else {
    }
  }
}
