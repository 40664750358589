import { Controller } from "@hotwired/stimulus"
import { leave } from "../src/transition"

// Connects to data-controller="removable"
export default class extends Controller {
  static values = {
    autoClose: Number
  }

  connect() {
    this.autoClose()
  }
w
  autoClose() {
    if (this.autoCloseValue == 0) return

    setTimeout(async () => {
      this.remove()
    }, this.autoCloseValue)
  }

  async remove() {
    await leave(this.element)
    this.element.remove()
  }


}
