window.utils = {}

export function railsEnv() {
  return document.querySelector('body').dataset.railsEnv;
}

export function isProduction() {
  return railsEnv() === 'production';
}

export function isDevelopment() {
  return railsEnv() === 'development';
}

export function isTest() {
  return railsEnv() === 'test';
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatCurrency(value) {
  return dollarFormatter.format(value)
}
let dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
utils.formatCurrency = formatCurrency;

