import { Controller } from "@hotwired/stimulus"
import { DXFViewer } from "three-dxf-viewer"
import { Boilerplate } from '../src/boilerplate.js';

let font_path = "https://raw.githubusercontent.com/ieskudero/three-dxf-viewer/main/example/fonts/helvetiker_regular.typeface.json"
// Connects to data-controller="dxf-viewer"
export default class extends Controller {
  static values = {
    fileUrl: String
  }

  connect() {
    this.setupScene().then(() => console.log("Scene installed"))
  }

  async setupScene() {
    let html = new Boilerplate()
    html.onLoad = async() => {
      html.three.clear()

      let dxf = await new DXFViewer().getFromPath(this.fileUrlValue, font_path)
      if(dxf) {
        html.three.addDXF(dxf)
      }
    }

    html.init()
  }
}
