import { Controller } from "@hotwired/stimulus"
import { getMaterials } from "../src/precision_library";

// Connects to data-controller="select-filter"
export default class extends Controller {
  static values = {
    style: String,
    filterId: String,
    filterTarget: String,
    filterValue: String,
    requestCount: Number,
  }

  connect() {
    let otherField = this.element.form.querySelector(`[name="${this.filterFieldName}"]`)
    if (otherField) {
      otherField.addEventListener("change", this.filterChanged.bind(this));
    }

    this.allOptions = [...this.element.options];
    this.filterValueChanged();
  }

  filterChanged(event) {
    const selected = event.target.selectedIndex;
    const value = event.target.options[selected].label;

    this.filterValueValue = value;

    this.filterValueChanged();
  }

  filterValueChanged() {
    switch (this.styleValue) {
      case 'brand':
        this.updateBrandFilter();
        break;
      default:
        console.log('Invalid filter style');
    }
  }

  // private
  get filterFieldName() {
    return `submission[${this.filterTargetValue}][${this.filterIdValue}]`;
  }

  async updateBrandFilter() {
    const materials = await this.materials();
    let options = materials[this.filter()] || [];
    this.element.innerHTML = '';
    this.allOptions
      .filter(o => options.includes(o.label))
      .forEach(o => this.element.appendChild(o));
  }

  filter() {
    switch (this.filterValueValue.toLowerCase()) {
      case 'solid surf':
        return 'solid surface'
      default:
        return this.filterValueValue.toLowerCase()
    }
  }

  async materials() {
    if (this._materials) {
      return this._materials;
    }

    this.requestCountValue++;
    const json = await getMaterials()
    this.requestCountValue--;

    this._materials = json.reduce((group, current) => {
      group[current.type.toLowerCase()] = group[current.type.toLowerCase()] || [];
      group[current.type.toLowerCase()].push(current.material);
      return group;
    }, {});

    return this._materials;
  }
}
