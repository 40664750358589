import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="element"
export default class extends Controller {
  static targets = ['submitButton']

  click() {
    this.submitButtonTargets.forEach(target => target.click());
  }
}
