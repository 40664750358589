import { Controller } from "@hotwired/stimulus"
import { getColorInformation } from "../src/precision_library"

// Connects to data-controller="color-preview"
export default class extends Controller {
  static targets = ['closeup', 'slab', 'vignette', 'noPreview', 'hasPreview', 'loading']
  static values = {
    brand: String,
    color: String,
  }

  brandValueChanged() {
    this.updatePreview()
  }

  colorValueChanged() {
    this.updatePreview()
  }

  set closeupImage(image) {
    if (!this.hasCloseupTarget) return;

    if (image) this.closeupTarget.querySelector('img').src = image
    this.closeupTarget.classList.toggle('hidden', !image)
  }

  set slabImage(image) {
    if (!this.hasSlabTarget) return;

    if (image) this.slabTarget.querySelector('img').src = image
    this.slabTarget.classList.toggle('hidden', !image)
  }

  set vignetteImage(image) {
    if (!this.hasVignetteTarget) return;

    if (image) this.vignetteTarget.querySelector('img').src = image
    this.vignetteTarget.classList.toggle('hidden', !image)
  }

  async updatePreview() {
    if (!this.validOptions()) {
      if (this.hasCloseupTarget) this.closeupTarget.classList.add('hidden')
      if (this.hasSlabTarget) this.slabTarget.classList.add('hidden')
      if (this.hasVignetteTarget) this.vignetteTarget.classList.add('hidden')
      this.noPreviewTarget.classList.remove('hidden');
      this.hasPreviewTarget.classList.remove('hidden');

      return
    }

    this.loadingTarget.classList.remove('hidden')
    const colorInfo = await getColorInformation(this.brandValue, this.colorValue)
    this.loadingTarget.classList.add('hidden')
    let hasPreview = !(colorInfo?.images?.closeupURL || colorInfo?.images?.slabURL || colorInfo?.images?.vignetteURL)
    this.hasPreviewTarget.classList.toggle('hidden', hasPreview)
    this.noPreviewTarget.classList.toggle('hidden', !hasPreview)

    this.closeupImage = colorInfo?.images?.closeupURL
    this.slabImage = colorInfo?.images?.slabURL
    this.vignetteImage = colorInfo?.images?.vignetteURL
  }

  validOptions() {
    return this.brandValid() && this.colorValid()
  }

  brandValid() {
    return this.brandValue && this.brandValue != ''
  }

  colorValid() {
    return this.colorValue && this.colorValue != ''
  }
  
}

