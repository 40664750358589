import { Controller } from "@hotwired/stimulus"
import { getColorInformation } from "../src/precision_library";

// Connects to data-controller="stone-color-preview"
export default class extends Controller {
  static targets = [ 'closeupImage', 'slabImage', 'noPreviewWarning' ]
  static values = {
    'brand': String,
    'color': String,
    'requestCount': Number,
  }

  brandValueChanged() {
    this.updatePreview();
  }

  colorValueChanged() {
    this.updatePreview();
  }

  // private

  async updatePreview() {
    if (this.brandValue == null || this.brandValue == '' || this.colorValue == null || this.colorValue == '') {
      this.closeupImageTarget.parentElement.classList.add('hidden');
      this.slabImageTarget.parentElement.classList.add('hidden');
      this.noPreviewWarningTarget.classList.remove('hidden');
      return;
    }

    // TODO: error handling
    this.requestCountValue++;
    const colorInfo = await getColorInformation(this.brandValue, this.colorValue);
    this.requestCountValue--;
    let noPreviews = true;
    
    if (colorInfo?.images?.closeupURL) {
      this.closeupImageTarget.src = colorInfo.images.closeupURL;
      this.closeupImageTarget.parentElement.classList.remove('hidden');
      noPreviews = false;
    } else {
      this.closeupImageTarget.parentElement.classList.add('hidden');
    }
    
    if (colorInfo?.images?.slabURL) {
      this.slabImageTarget.src = colorInfo.images.slabURL;
      this.slabImageTarget.parentElement.classList.remove('hidden');
      noPreviews = false;
    } else {
      this.slabImageTarget.parentElement.classList.add('hidden');
    }

    this.noPreviewWarningTarget.classList.toggle('hidden', !noPreviews);
  }
}
