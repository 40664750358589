import { Controller } from "@hotwired/stimulus"
import { getSinkInfo } from "../src/precision_library"

// Connects to data-controller="sink-preview"
export default class extends Controller {
  static targets = ['image']
  static values = {
    sinkName: String,
    sinkNumber: Number,
  }

  sinkNameValueChanged() {
    this.updatePreview()
  }

  sinkNumberValueChanged() {
    this.updatePreview()
  }

  async updatePreview() {
    const sinkInfo = await getSinkInfo(this.sinkNameValue)
    if (!sinkInfo || !sinkInfo.mainImageURL) {
      this.imageTarget.classList.add('hidden')
      return
    }

    this.imageTarget.classList.remove('hidden')
    this.imageTarget.src = sinkInfo.mainImageURL
  }
}
