import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="color-preview"
export default class extends Controller {
  connect() {
    this.element.classList.add('opacity-1', 'transition-all')

    setTimeout(() => {
      this.element.classList.add('opacity-0')
      setTimeout(() => {
        this.element.remove()
      }, 200)
    }, 2000)
  }
}
