import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

// Connects to data-controller="banner"
export default class extends Controller {
    static values = {
        bannerId: String
    }

    connect() {
        if (this.dismissedBanner(this.bannerIdValue)) {
            this.element.remove()
        } else {
            this.element.classList.remove("hidden")
        }
    }

    close() {
        let banners = this.dismissedBannerIds()
        banners.unshift(this.bannerIdValue)
        Cookies.set("dismissedBanners", banners)
        this.element.remove()
    }

    dismissedBanner(bannerId) {
        let banners = this.dismissedBannerIds()
        return banners.indexOf(bannerId) != -1
    }

    dismissedBannerIds() {
        let ids = Cookies.get('dismissedBanners')?.split(",") || []
        return ids.slice(0, 10)
    }
}