import { Controller } from "@hotwired/stimulus";
import {getBrandColors, getMaterials} from "../src/precision_library";

export default class extends Controller {
  static values = {
    brand: String,
    materialType: String
  }

  originalOptions = []

  async initialize() {
    this.allBrands = await getMaterials()

    this.updateOptions()
  }

  connect() {
    this.originalOptions = [...this.element.options]
    this.originalOptions.forEach(o => o.remove())
  }

  async updateOptions() {
    let materialType = this.materialType
    let brand = this.brand
    if (!materialType || !brand) return

    // let brands = this.allBrands.filter(brand => brand.type == materialType).map(brand => brand.material)
    // let colors = (await Promise.all(brands.map(brand => getBrandColors(brand).then(colors => colors.map(c =>  `${brand} ${c}`))))).flat();
    let colors = (await getBrandColors(brand)).map(c => `${brand} ${c}`)
    this.element.innerHTML = ""

    this.originalOptions
        .filter(o => colors.indexOf(o.text.replace(" (directional)", "")) !== -1)
        .forEach(o => this.element.append(o));
  }

  materialTypeValueChanged() {
    this.updateOptions()
  }

  brandValueChanged() {
    this.updateOptions()
  }


  get materialType() {
    switch (this.materialTypeValue) {
      case "STONE":
        return "Stone"
      case "ULTRA COMPACT":
        return "Ultracompact"
      default:
        return this.materialTypeValue
    }
  }

  get brand() {
    switch (this.brandValue) {
      case "Q Quartz":
        return "Q Quartz"
      default:
        return this.brandValue.replace(" Quartz", "")
    }
  }
}
