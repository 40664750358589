import { Boilerplate3D } from './boilerplate3d';

export class Boilerplate {

    constructor() {
        this.onLoad = null;
    }

    init() {

        //scene managing code
        this.three = new Boilerplate3D();
        this.onLoad()

        //attach load file & drop  event listener
        // const input = document.getElementById( 'file' );
        // input.addEventListener( 'change', async ( e ) => await this.loadFile( e ) );
        // const canvas3d = document.getElementById( 'canvas3d' );
        // canvas3d.addEventListener( 'drop', async ( e ) => {
        //     e.preventDefault();
        //     await this.loadFile( e );
        // } );
        // canvas3d.addEventListener( 'dragover', ( e ) => e.preventDefault() );

        // this.loading = document.getElementById( 'loading' );
    }
}