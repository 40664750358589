import { Controller } from "@hotwired/stimulus"
import { getEdges } from "../src/precision_library";

export default class extends Controller {
  static targets = ['loading', 'template', 'options', 'missingOptionField']
  static values = {
    materialType: String,
    finalSelectId: String,

    defaultFinalOptions: String, // this is a json dump TODO: switch to Object
  }

  connect() {
    this.loadOptions();
    this.element[this.identifier] = this
  }

  async loadOptions() {
    if (!this.hasMaterialTypeValue) { return }

    this.loadingTarget.classList.remove('hidden')
    this.optionsTarget.classList.add('hidden')
    const edges = await getEdges(this.materialType) || []
    this.clearOptions()
    this.loadingTarget.classList.add('hidden')
    this.optionsTarget.classList.remove('hidden')

    edges
      .filter(e => e.offeredInCw)
      .forEach(edge => {
        const node = this.templateTarget.content.cloneNode(true)
        node.firstElementChild.setAttribute('x-data', JSON.stringify(edge))
        this.optionsTarget.append(node)
      });
  }

  clearOptions() {
    this.optionsTarget.innerHTML = ""
  }

  materialTypeValueChanged() {
    this.loadOptions()
  }

  confirmInitial(initialValue, initialLabel) {
    const target = document.querySelector(`[data-edge-profile-initial-id='${initialValue}']`)

    if (target) {
      target.checked = true;
      this.missingOptionFieldTarget.value = ''
    } else {
      this.missingOptionFieldTarget.value = this.finalOptionWithLabel(initialLabel)?.moraware_id
    }
  }

  defaultOptions() {
    return JSON.parse(this.defaultFinalOptionsValue);
  }

  finalOptionWithLabel(initialLabel) {
    return this.defaultOptions().find(o => o.value == initialLabel)
  }

  get materialType() {
    switch (this.materialTypeValue) {
      case "SOLID SURF": return "Solid Surface"
      case "ULTRA COMPACT": return "Ultracompact"
      default: return this.materialTypeValue
    }
  }
}
