// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import Alpine from 'alpinejs'
import Honeybadger from '@honeybadger-io/js';
import LocalTime from "local-time"
import { railsEnv } from "./src/util"

window.Alpine = Alpine
Alpine.start()

Honeybadger.configure({
  apiKey: 'hbp_Z0xRXr488SHSdnF0BB7o9Vg1u14cSM3Mly5F',
  environment: railsEnv(),
  // revision: 'git SHA/project version'
});

LocalTime.start()

document.addEventListener('turbo:load', () => { 
  let body = document.querySelector('body')
  body.className = body.className.replace('no-js', 'js');
});
