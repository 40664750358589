import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autosubmit"
export default class extends Controller {
  timeout = null;

  submit(event) {
    clearTimeout(this.timeout);
    let delay = event.type == 'input' ? 1500 : 1;

    this.timeout = setTimeout(() => {
      event.target.form.requestSubmit();
    }, delay);
  }
}
