import { Controller } from "@hotwired/stimulus"
import { loader } from '../src/google'

// Connects to data-controller="street-view"
export default class extends Controller {
  static values = {
    search: String,
  }

  async connect() {
    const google = await loader.load()
    const geocoder = new google.maps.Geocoder();
    const { results, status } = await geocoder.geocode({ address: this.searchValue });
    if (results.length == 0) return;

    const lat = results[0].geometry.location.lat();
    const lng = results[0].geometry.location.lng();
    const pano = new google.maps.StreetViewPanorama(this.element, {
      position: { lat:lat, lng: lng },
      disableDefaultUI: true,
    });

    pano.addListener("status_changed", () => {
      if (pano.getStatus()  == 'OK') {
        this.element.classList.remove('hidden')
      } else {
        this.element.classList.add('hidden')
      }
    });
  }
}